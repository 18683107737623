import "./styles.css";
import { useState } from "react";
import QrReader from "modern-react-qr-reader";
import axios from "axios";
import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardImage,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import logo from "./TerraCharge_splash01.png";
import start_charging_logo from "./charging.gif";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

const App = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [processing] = useState(false);
  const [, setPrecScan] = useState("");
  const [startChargingPage, setStartChargingPage] = useState(false);
  const [selected, setSelected] = useState("environment");
  const [scanned, setScanned] = useState(true);
  const [url, setUrl] = useState("");
  const [gourl, setGoUrl] = useState("");

  async function fetchData({ qr = "" }) {
    try {
      // setProcessing(true);
      // const result = await axios.get(
      //     `https://ucs-goma-backend.herokuapp.com/payement/scan?matricule=${qr}&forThisYear=1`
      // );
      // console.log("scanned code", qr);
      // const { message, payement } = result.data;
      // console.log(payement);
      // if (!message) {
      //     setCode({
      //         text: payement.matricule,
      //         identite: `${payement.nom} ${payement.postnom} ${payement.prenom}`,
      //         promotion: payement.auditoire,
      //         annee: payement.annee,
      //         frais: Number.parseFloat(payement.totalPayer),
      //         total: Number.parseFloat(payement.totalAPayer),
      //         recouvrement: "Premiere tranche",
      //         maxEncours: 800,
      //     });
      //     // setPrecScan(null);
      //     setDiaglog(true);
      // } else {
      //     setCode(null);
      //     setPrecScan(null);
      //     setErrorMessage(message);
      //     setDiaglog(true);
      // }
    } catch (error) {
      console.log(error);
    }
  }

  const handleScan = async (scanData) => {
    if (scanData && scanData !== "" && !showDialog && !processing) {
      console.log(`loaded >>>`, scanData);
      // setPrecScan(scanData);
      const urlpath =
        "https://pro.mobile.chargers.japan.terracharge.net/one-time-payment?c=";
      const str2 = urlpath.concat(scanData);
      if (scanData.length === 16 || scanData.length === 12) {
        setScanned(false);
        setUrl(str2);
        setStartChargingPage(true);
      }

      //await fetchData({ qr: scanData });
    }
  };
const handleStartCharging = () => {
    setGoUrl(url)
}
useEffect(() => {
    if (!scanned) {
      setShowDialog(true);
      window.location.replace(gourl);
    }
    console.log(gourl)

}, [gourl]);

  const handleError = (err) => {
    console.error(err);
  };
  return (
    <>
    {startChargingPage && 
    <div className="App">
        <span className="small text-left">
        <h3 style={{textAlign: "left"}}> 充電の準備 </h3>{" "}
      </span>{" "}
        <MDBCardImage
        src={start_charging_logo}
        className="img-fluid mt-5"
        waves
        style={{
          height: "30vh",
          weight: "30vw",
        }}
      />
      <span className="small mt-2 mt-5 mt-10 m-5" style={{marginTop: "60px", padding:"20px"}}>
        <h6> 充電コネクタをロックするまで差し込んでください。<br/>コネクタが接続されていることを車体のモニター側でも確認してください。 </h6>{" "}
      </span>{" "}
      <div className="text-center footer-bottom mt-10" style={{padding: "20px"}}>
        <button type="button" class="rounded-pill" onClick={handleStartCharging}>接続できたら次へ進む</button><br/>
        <button type="button" class="rounded-back" onClick={()=>setStartChargingPage(false)}>戻る</button>
        
         {/* <button type="button" class="btn btn-primary btn-lg" onClick={handleStartCharging}>充電を開始する</button><br/>
         <button type="button" class="btn btn-outline-primary" data-mdb-ripple-color="dark" onClick={()=>setStartChargingPage(false)}>戻る</button> */}

      </div>
      
    </div>
    }
    {!startChargingPage && 
    <div className="App">
      <MDBCardImage
        src={logo}
        className="img-fluid mt-5"
        waves
        style={{
          height: "15vh",
          weight: "15vw",
        }}
      />{" "}
      <div className="d-flex align-items-center mt-5" />
      <select
        style={{
          height: "30px",
          fontSize: "14px",
          borderRadius: "7px",
        }}
        onChange={(e) => setSelected(e.target.value)}
      >
        <option value={"environment"}> リアカメラ </option>{" "}
        <option value={"user"}> フロントカメラ </option>{" "}
      </select>{" "}
      <div className="d-flex align-items-center mt-3" />
      <span className="small mt-2 mt-5">
        <h6> 充電器に貼付けされている QRコード をスキャンしてください </h6>{" "}
        <br />{" "}
      </span>{" "}
      
      {
        <div
          style={{
            padding: "20px",
            borderRadius: "20px",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            borderColor: !showDialog ? "" : "green",
            borderWidth: "7px",
          }}
        >
          <QrReader
            facingMode={selected}
            delay={500}
            onError={handleError}
            onScan={handleScan}
            // chooseDeviceId={()=>selected}
            style={{
              width: "250px",
              heigth: "300px",
            }}
          />
        </div>
      }{" "}
      <div className="text-center footer-bottom mt-40">
        <u><a
          href="https://terra-charge.co.jp/"
          className="colorblk"
          rel="noopener noreferrer"
          target="_blank"
        >
          {" "}
          ©2024 Terra Charge{" "}
        </a>{" "}
        </u>
        <br />
        <u><a
          href="https://terra-admin-site-pro.s3.ap-northeast-1.amazonaws.com/docs/privacy.html"
          target="_blank"
          rel="noopener noreferrer"
          className="colorblk"
        >
          プライバシーポリシー {"  "}  |{"  "}
        </a>{" "}
        </u>
        <u><a
          href="https://terra-admin-site-pro.s3.ap-northeast-1.amazonaws.com/docs/convention.html"
          target="_blank"
          rel="noopener noreferrer"
          className="colorblk"
        >
          利用規約{" "}
        </a>{" "}
        </u>
        <div id="space" />
      </div>
    </div>}
    </>
  );
};

export default App;
